<template>
  <div class="restore__container">
    <app-spinner class="restore__spinner" />
    <p>{{ $t('loadingConfiguration') }}</p>
  </div>
</template>

<script>
import AppSpinner from '@/components/base/AppSpinner';
import StepsMixin from '@/mixins/StepsMixin';
import { mapActions } from 'vuex';

export default {
  name: 'RestoreConfiguration',

  components: {
    AppSpinner
  },

  mixins: [StepsMixin],

  created() {
    this.restoreConfiguration();
  },

  methods: {
    ...mapActions(['fetchConfiguration']),

    async restoreConfiguration() {
      const { step } = await this.fetchConfiguration(this.$route.params.configurationId);

      this.currentStep = step;
    }
  }
};
</script>

<style scoped lang="scss">
.restore__container {
  margin: 5rem auto;
  text-align: center;
}

.restore__spinner {
  margin: 2rem auto;
}
</style>
